import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import Form from '../../components/Form'

import { useFormData } from '../../hooks/gravityForms'
import { H1 } from '../../components/Headings'
import Container from '../../components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

const PageTemplate = ({data}) => {
    const page = data.allWpPage.nodes[0]

    const allFormData = useFormData()

    const handleError = ({values, error, reset}) => {
        //handle error
    }
    
    const handleSuccess = ({values, reset, confirmations}) => {
        //handle success
    }

    return (
        <>
            <SEO />
            <Layout>
              <Container tw="grid grid-cols-12 col-gap-8 py-12">
                <div>
                  <FontAwesomeIcon icon={faHome} size="5x" tw="text-yellow-600" />
                </div>
                <div tw="col-span-8">
                  <H1>
                    {page.title}
                  </H1>
                  <div tw="mb-12 prose max-w-full" dangerouslySetInnerHTML={{ __html: page.content }} />
                  <Form
                      id={1}
                      allFormData={allFormData}
                      successCallback={handleSuccess}
                      errorCallback={handleError}
                  />
                </div>
              </Container>
            </Layout>
        </>
    )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        content
      }
    }
  }
`